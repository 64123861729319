import React from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Gallery.css";

const galleryImages = [
  images.gallery01,
  images.gallery02,
  images.gallery03,
  images.gallery04,
];

const Gallery = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div id="gallery" className="app__gallery flex__center">
      <div className="app__gallery-content">
        <h1 className="headtext__cormorant">Photo Gallery</h1>
        <SubHeading title="" />
        {/* <p className='p__opensans' style={{color:'#AAA', marginTop: '2rem' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisque egestas mu.</p> */}
        <button type="button" className="custom__button">
          <a
            href="https://www.instagram.com/pignettistemple/"
            target="_blank"
            rel="noreferrer"
          >
            View More
          </a>
        </button>
      </div>

      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {galleryImages.map((image, index) => (
            <div
              className="app__gallery-images_card flex__center"
              key={`gallery_image-${index + 1}`}
            >
              <img src={image} alt="gallery" />
              {/* <BsInstagram className='gallery__image-icon' /> */}
            </div>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort
            className="gallery__arrow-icon"
            onClick={() => scroll("left")}
          />
          <BsArrowRightShort
            className="gallery__arrow-icon"
            onClick={() => scroll("right")}
          />
        </div>
      </div>
    </div>
  );
};
export default Gallery;

// import React, { useEffect, useState } from 'react';
// import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
// import axios from 'axios';

// import { SubHeading } from '../../components';
// import './Gallery.css';

// // Replace these with the IDs of the photos you want
// const photoIds = ['MQUqbmszGGM', 'kfDsMDyX1K0', 'amYCy53AOSU', 'NzHRSLhc6Cs'];

// const Gallery = () => {
//   const [galleryImages, setGalleryImages] = useState([]);
//   const scrollRef = React.useRef(null);

//   useEffect(() => {
//     Promise.all(
//       photoIds.map((id) =>
//         axios.get(
//           `https://api.unsplash.com/photos/${id}?client_id=omD3V6C5ZLmOL2bDSfanjaRKzR8Fu9abRohWQA4VNmI`
//         )
//       )
//     )
//       .then((responses) => {
//         setGalleryImages(responses.map((res) => res.data.urls.small));
//       })
//       .catch((err) => console.log(err));
//   }, []);

//   const scroll = (direction) => {
//     const { current } = scrollRef;

//     if (direction === 'left') {
//       current.scrollLeft -= 300;
//     } else {
//       current.scrollLeft += 300;
//     }
//   };

//   return (
//     <div id='gallery' className='app__gallery flex__center'>
//       <div className='app__gallery-content'>
//         <h1 className='headtext__cormorant'>Photo Gallery</h1>
//         <SubHeading title='' />
//         <button type='button' className='custom__button'>
//           <a
//             href='https://www.instagram.com/pignettistemple/'
//             target='_blank'
//             rel='noreferrer'
//           >
//             View More
//           </a>
//         </button>
//       </div>

//       <div className='app__gallery-images'>
//         <div className='app__gallery-images_container' ref={scrollRef}>
//           {galleryImages.map((image, index) => (
//             <div
//               className='app__gallery-images_card flex__center'
//               key={`gallery_image-${index + 1}`}
//             >
//               <img src={image} alt='gallery' />
//             </div>
//           ))}
//         </div>
//         <div className='app__gallery-images_arrows'>
//           <BsArrowLeftShort
//             className='gallery__arrow-icon'
//             onClick={() => scroll('left')}
//           />
//           <BsArrowRightShort
//             className='gallery__arrow-icon'
//             onClick={() => scroll('right')}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Gallery;
